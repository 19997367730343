"use client";

import { useDisclosure } from "@mantine/hooks";
import Burger from "./burger";
import { Drawer } from "@mantine/core";
import Line from "../accordion/line";
import Link from "next/link";
import Logo from "@/app/components/ui/logo-svg";
import CloseButtonSvg from "../close-btn-svg";

export default function BurgerButton() {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <button onClick={open} className="burger-btn">
        <Burger />
      </button>
      <Drawer
        opened={opened}
        onClose={close}
        size="100%"
        withCloseButton={false}
        className="mobile-menu"
      >
        <div className="logo">
          <Logo />
        </div>
        <div className="tab tab-nav-mobile">
          <Line>
            <Link href="/projets">Projets</Link>
          </Line>
          <Line>
            <Link href="/actualites">Actualités</Link>
          </Line>
          <Line>
            <Link href="/philosophie">Philosophie</Link>
          </Line>
          <Line>
            <Link href="/agence">Agence</Link>
          </Line>
        </div>

        <div className="close-btn">
          <button onClick={close}>
            <CloseButtonSvg />
          </button>
        </div>
      </Drawer>
    </>
  );
}
