export default function CloseButtonSvg() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3145 14.3497L1.68755 1.7228"
        stroke="#202020"
        stroke-linecap="round"
      />
      <line
        x1="14.3262"
        y1="1.35384"
        x2="1.56458"
        y2="14.1154"
        stroke="#202020"
        stroke-linecap="round"
      />
    </svg>
  );
}
