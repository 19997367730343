export default function Burger() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="25" fill="#202020" />
      <line
        x1="32.5"
        y1="19.5"
        x2="17.5"
        y2="19.5"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="32.5"
        y1="25.5"
        x2="17.5"
        y2="25.5"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="32.5"
        y1="31.5"
        x2="17.5"
        y2="31.5"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
}
